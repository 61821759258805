import {Logger} from '../logging/logger.types'

export abstract class StorageService {
  protected constructor(
    private readonly storage: Storage,
    private readonly logger: Logger,
  ) {
    try {
      this.storage.setItem('test', 'test')
      this.storage.removeItem('test')
    } catch (e) {
      this.logger.warn(`Storage is not usable`)
      this.storage = new InMemoryStorage()
    }
  }

  getItem(key: string): string | undefined {
    return this.storage.getItem(key) || undefined
  }

  getObject<T>(key: string): T | undefined {
    const item = this.getItem(key)

    if (item) {
      return JSON.parse(item) as T
    }
    return undefined
  }

  setItem(key: string, item: string): string {
    this.storage.setItem(key, item)
    return key
  }

  setObject<T>(key: string, item: T): string {
    // undefined is not valid JSON, so we remove the item instead
    if (item === undefined) {
      return this.removeItem(key)
    }
    return this.setItem(key, JSON.stringify(item))
  }

  removeItem(key: string): string {
    this.storage.removeItem(key)
    return key
  }

  clearStorage(): void {
    this.storage.clear()
  }
}

export class InMemoryStorage implements Storage {
  private readonly inMemoryStorage = new Map<string, any>() // eslint-disable-line @typescript-eslint/no-explicit-any

  get length(): number {
    return this.inMemoryStorage.size
  }

  clear(): void {
    this.inMemoryStorage.clear()
  }

  getItem(key: string): string | null {
    return this.inMemoryStorage.get(key)
  }

  key(index: number): string | null {
    return this.inMemoryStorage.keys()[index]
  }

  removeItem(key: string): void {
    this.inMemoryStorage.delete(key)
  }

  setItem(key: string, value: string): void {
    this.inMemoryStorage.set(key, value)
  }
}
